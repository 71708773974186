import React from "react"
import AwardCategoryIcon from "./award-category-icon"

const AwardCategory = ({children, title, icon, gridColor}) => (
  <div style={{
    position: `relative`
  }}>
    <div style={{
      position: `absolute`,
      width: `100%`,
      height: `100%`,
      background: `repeating-linear-gradient(-45deg, ${gridColor}, ${gridColor} 4px, transparent 4px, transparent 10px)`,
      top: `-1rem`,
      right: `-1rem`,
      zIndex: `-1`
    }} />
    <div className="media" style={{
      backgroundColor: `#fff`,
      padding: `3rem`,
      paddingBottom: `2rem`
    }}>
      <div className="media-body" style={{
        color: `#30c4bf`,
        fontSize: `1rem`,
        lineHeight: `1.8rem`,
        fontFamily: `Din Pro`
      }}>
        <h5 className="mt-0" style={{
          color: `#d23464`,
          fontWeight: `bold`
        }}>{title}</h5>
        <hr style={{
          backgroundColor: `#d23464`,
          margin: `1rem 0`
        }} />
        {children}
      </div>
      <div style={{
        position: `relative`
      }}>
        <div style={{
          position: `absolute`,
          width: `5rem`,
          height: `5rem`,
          background: `repeating-linear-gradient(-135deg, ${gridColor}, ${gridColor} 2px, transparent 2px, transparent 4px)`,
          top: `.1rem`,
          right: `-.3rem`,
          borderRadius: `50%`
        }}>
        </div>
        <div className="ml-4"><AwardCategoryIcon icon={icon} /></div>
      </div>
    </div>
  </div>
)

export default AwardCategory
